import React from "react"
import Layout from "../../components/layout"
import TextSection from "../../components/project/intro/text-section"
import ProjectDetails from "../../components/project/intro/project-details"
import Spread from "../../components/project/spread"
import {
  Hero,
  Image,
  ImageCaption,
  Close,
  projectWrapper,
  SpreadText,
  SpreadImageGrid,
  FullWidthImage,
} from "../../styles/project-styles"
import SpreadTitle from "../../components/project/spread-title"
import CaptionLeft from "../../components/project/caption-left"
import Understand from "../../components/project/process/understand"
import Design from "../../components/project/process/design"
import Prototype from "../../components/project/process/prototype"
import Img from "gatsby-image"
import SmoothScroll from "../../components/smooth-scroll"
import { motion } from "framer-motion"

const Nomad = ({ data }) => {
  const {
    dreamnestHero,
    collegeStudent,
    redWood,
    dreamnestFront,
    sproul,
    construction1,
    sculptureInprogress1,
    sketch,
    glade,
    gladeFinal,
    dreamnestFinal,
  } = data
  return (
    <motion.div
      variants={projectWrapper}
      initial="open"
      animate="open"
      exit="close"
    >
      <Close to="/work/" />
      <Layout project>
        {/* Hero */}
        <Spread fluid>
          <Hero
            gridRowEnd={3}
            imgUrl={dreamnestHero.childImageSharp.fluid.src}
            aspectRatio={1920 / 1080}
          />
        </Spread>

        <Spread>
          {/* place in data folder */}
          <SpreadText colStart={8} colEnd="span 5">
            <TextSection
              title="Challenge"
              text={
                <>
                  According to{" "}
                  <a href="https://bit.ly/2TmoS7l">
                    a study conducted by two british researchers
                  </a>
                  , millennial college students today are more perfectionistic
                  and concerned with success than previous generations.
                  Personally I believe this pressure to succeed often translates
                  into choosing a course of study that is not aligned with one’s
                  passions. For the final class project in Design Innovation
                  190T, my team decided to explore this topic further to see if
                  we could help UC Berkeley students reconnect with their
                  passions.
                </>
              }
            />
            <TextSection
              title={"Outcome"}
              text="The result of our exploration was a sculpture called Dreamnest. Like a bird’s nest perched on a tree branch, Dreamenst was built to give Berkeley students a hideaway to reconnect with their dreams and aspirations. Inside the nest we posed the question “What do you want to do before you die?“ and encouraged the students to write their responses on the walls. By the end of the project all the walls were filled with beautifully honest and inspirational responses."
              style={{ paddingTop: "2rem" }}
            />
            <ProjectDetails
              type={"class project, sculpture"}
              contributions={"design research, construction"}
              rowStart={3}
              alignSelf="end"
              style={{ paddingTop: "4rem" }}
            />
          </SpreadText>
          <SpreadImageGrid colStart={1} colEnd="span 7" rowStart={1}>
            <Image colStart={1} colEnd={"span 6"} rowEnd={"span 3"}>
              <Img fluid={collegeStudent.childImageSharp.fluid} />
            </Image>
            <Image colStart={1} colEnd="span 3" rowEnd="span 3">
              <Img fluid={redWood.childImageSharp.fluid} />
              <ImageCaption>source: unsplash</ImageCaption>
            </Image>
            <Image colStart={4} colEnd="span 3" rowEnd="span 3">
              <Img fluid={dreamnestFront.childImageSharp.fluid} />
            </Image>
          </SpreadImageGrid>
        </Spread>

        <Spread>
          <SpreadTitle
            text="The Process"
            colStart={1}
            colEnd="span 4"
            rowStart={1}
            rowEnd="span 1"
          />
          <SpreadText colEnd="span 4">
            <Understand text="To better understand how Berkeley students relate to their dreams and passions we went around campus and interviewed students. The interviews were loosely centered around the question: “Do Berkeley students study what they love?” From the interviews we learned that many students had in fact pursued majors based on career prospects and not based on passions. We also observed that many of them became noticeably more excited when we asked them about their interests and dreams." />
            <Design text="The sculpture idea came after the interviews as we thought “what if we create a space where people can feel safe in speaking their passions…in a performative, holistic, artistic way?”. Shaped like a hexagon with a crown of pine branches we imagined the sculpture to function like a “birds nest for dreams” that students could step into. On the center wall we wrote the inspiring, yet slightly provocative question: “What do you want to do before you die”? To encourage students to write and share anonymous responses we included a small shelf filled with sharpies." />
            <Prototype text="Building a 8 foot sculpture on a limited budget and with a tight deadline was no easy feat. We searched all over Berkeley for cheap materials and with the help of our teammate Marcus (who is a skilled builder) we managed to pull it off. We placed the sculpture in different locations all over campus and in the end it was a big success. All the walls were covered in honest and inspiring responses and we received the highest grade possible in our class." />
          </SpreadText>

          <SpreadImageGrid colStart={6} colEnd="span 7">
            <Image colStart={1} colEnd="span 4" rowStart={1} rowEnd="span 3">
              <Img fluid={sproul.childImageSharp.fluid} />
            </Image>
            <Image colStart={5} colEnd="span 3" rowStart={1} rowEnd="span 4">
              <Img fluid={construction1.childImageSharp.fluid} />
            </Image>

            <Image colStart={1} colEnd="span 4" rowStart={4} rowEnd="span 3">
              <Img fluid={sculptureInprogress1.childImageSharp.fluid} />
            </Image>
            <Image
              colStart={5}
              colEnd="span 3"
              rowStart={5}
              rowEnd="span 2"
              //   alignSelf="start"
            >
              <Img fluid={sketch.childImageSharp.fluid} />
            </Image>
          </SpreadImageGrid>
        </Spread>

        <Spread>
          <FullWidthImage>
            <Img fluid={glade.childImageSharp.fluid} />
            <ImageCaption>Berkeley students exploring Dreamnest</ImageCaption>
          </FullWidthImage>
        </Spread>
        <Spread>
          <FullWidthImage>
            <Img fluid={gladeFinal.childImageSharp.fluid} />
            <ImageCaption>
              Just a few days after the sculpture's reval it was getting getting
              covered in writing:)
            </ImageCaption>
          </FullWidthImage>
        </Spread>
        <Spread>
          <FullWidthImage>
            <Img fluid={dreamnestFinal.childImageSharp.fluid} />
            <ImageCaption>The final state of the sculpture</ImageCaption>
          </FullWidthImage>
        </Spread>
      </Layout>
    </motion.div>
  )
}

export default Nomad

export const pageQuery = graphql`
  query {
    dreamnestHero: file(relativePath: { eq: "dreamnest/dreamnest-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    collegeStudent: file(
      relativePath: { eq: "dreamnest/charles-deloye-2RouMSg9Rnw-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    redWood: file(
      relativePath: {
        eq: "dreamnest/jessica-ruscello-qZ49jNHh1i0-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dreamnestFront: file(
      relativePath: { eq: "dreamnest/dreamnest-front.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sproul: file(relativePath: { eq: "dreamnest/sproul.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    construction1: file(relativePath: { eq: "dreamnest/construction-1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sculptureInprogress1: file(
      relativePath: { eq: "dreamnest/sculpture-inprogress-1.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sketch: file(relativePath: { eq: "dreamnest/sketch-2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    glade: file(relativePath: { eq: "dreamnest/glade.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gladeFinal: file(relativePath: { eq: "dreamnest/glade-final.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dreamnestFinal: file(
      relativePath: { eq: "dreamnest/dreamnest-final.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
